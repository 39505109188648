import { Suspense, lazy, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import isToday from 'dayjs/plugin/isToday';
import 'swiper/css';

import '@bluecodecom/fonts';

import { LoaderProvider } from '@bluecodecom/loader';

import { ThemeProvider } from '@bluecodecom/theme';

import { nativeService } from '@bluecodecom/native';

import { trackingHooks } from '@bluecodecom/handyparken-webview/features/tracking';

import './i18n';

dayjs.extend(isToday);

const App = lazy(() => import('./app'));

const Main = () => {
  const {
    i18n: { language },
  } = useTranslation();
  useEffect(() => {
    nativeService.allowResumeMiniApp();
    nativeService.hideNavigation();
    dayjs.locale(language || 'de');
  }, [language]);

  trackingHooks.useTrackEvent('Opened');

  return (
    <LoaderProvider>
      <ThemeProvider observe>
        <BrowserRouter>
          <Suspense>
            <App />
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </LoaderProvider>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(<Main />);
